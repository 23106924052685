// IMPORTS
@import (reference) "../settings.less";

// FILTER
.close-filter,
.close-filter-bottom {
    display: none;
}

.filter-trigger {
    color: @font-color;
    min-width: 282px;
    height: 48px;
    text-align: left;
    position: relative;
    padding: 0 38px 0 16px;
    z-index: 1;
    background-color: @light-bg;
    border-radius: var(--borderRadius);

    &:before {
        content: 'Filtrowanie';
        background-color: white;
        position: absolute;
        top: -20px;
        left: 0;
        font-size: 12px;
        font-weight: 700;
        color: @font-color;
        z-index: 11;
        pointer-events: none;
    }

    &:after {
        content: '\e912';
        font-family: icomoon;
        position: absolute;
        top: 0;
        right: 0;
        width: 48px;
        height: 48px;
        color: @header-color;
        font-size: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &:hover {
        background-color: darken(@light-bg, 5%);
    }
}

// STANDARD FILTER
body[data-canvas-filter] {

    &:has(#filter .gs-filtr-container) {

        .navigation-wrapper {
            display: flex;
        }

        .filter-trigger {
            display: block;
        }
    }
}

.filter-sorter-wrapper {
    border: 4px solid @border-color;
    border-top: 0;
    border-bottom-width: 1px;
    padding: 24px 17px;
    
    .sorter {

        &:before {
            display: none;
        }
    }

    .sorter-button {
        min-width: 0;
        width: 100%;
    }
}

@media (min-width: 1280px) {
    body:not([data-canvas-filter]) {

        .filter-trigger {
            display: none;
        }

        #filter {
            border-radius: var(--borderRadius);
            overflow: hidden;
            display: none;

            &:has(.gs-filtr-container) {
                display: block;
            }

            > h3 {
                display: block;
                color: white;
                background-color: @sec-color;
                font-size: 16px;
                font-weight: 700;
                padding: 14px 23px 13px;
            }

            .gs-filtr-container {
                border: 4px solid @border-color;
                border-top: 0;
                border-bottom-width: 1px;
                padding: 24px 17px;

                &:last-of-type {
                    border-bottom-width: 4px;
                }

                > strong {
                    display: block;
                    font-weight: 700;
                    margin-bottom: 8px;
                    color: @header-color;

                    span {
                        display: none;
                    }

                    &:first-letter {
                        text-transform: uppercase;
                    }
                }

                input[type="checkbox"] {
                    display: none;
                }

                input:checked+label {

                    &:after {
                        transform: scale(1);
                    }
                }

                label {
                    display: block;
                    cursor: pointer;
                    user-select: none;
                    padding: 4px 0;
                    padding-left: 34px;
                    position: relative;
                    color: @font-color;
                    font-size: 14px;

                    &:hover {
                        text-decoration: underline;
                    }

                    &:before {
                        content: '';
                        position: absolute;
                        top: 5px;
                        left: 0;
                        width: 20px;
                        height: 20px;
                        background-color: lighten(@sec-color, 50%);
                        transition: border-radius .1s ease-in-out;
                    }

                    &:after {
                        content: '\e801';
                        font-family: icomoon;
                        position: absolute;
                        top: 5px;
                        left: 0;
                        width: 20px;
                        height: 20px;
                        color: @header-color;
                        font-size: 14px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        transition: transform .2s ease-in-out;
                        transform: scale(0);
                    }
                }

                &.colors {

                    ul {
                        display: flex;
                        flex-wrap: wrap;
                        gap: 4px;
                    }

                    li {
                        display: block;
                    }

                    label {
                        font-size: 0;
                        padding: 0;
                        width: 34px;
                        height: 34px;
                        border: 2px solid @border-color;
                        outline: 4px solid white;
                        outline-offset: -5px;

                        &:before,
                        &:after {
                            display: none;
                        }

                        &:hover {
                            border-color: @header-color;
                        }
                    }

                    input:checked + label {
                        border-color: @header-color;
                        outline-width: 3px;
                    }
                }

                &.producers {

                    ul {
                        display: flex;
                        flex-wrap: wrap;
                        gap: 4px;
                    }

                    li {
                        display: block;
                    }

                    label {
                        padding: 3px 15px;
                        background-color: white;
                        border: 1px solid @border-color;
                        font-size: 14px;

                        &:before,
                        &:after {
                            display: none;
                        }

                        &:hover {
                            border-color: @header-color;
                            text-decoration: none;
                        }
                    }

                    input:checked + label {
                        border-color: @header-color;
                        background-color: @header-color;
                        color: white;
                    }
                }
            }
        }
    }

    // FILTER ON CANVAS
    body[data-canvas-filter] {

        #filter {
            position: fixed;
            top: 0;
            z-index: 10000;
            background-color: white;
            width: 290px;
            height: 100vh;
            overflow-y: auto;
            padding: 8px 12px;
            transform: translateX(-100%);
            transition: transform .3s ease-in-out;
            color: white;

            > h3 {
                font-size: 22px;
                margin-bottom: 8px;
                font-weight: bold;
                text-transform: uppercase;
                font-family: @main-font-family;
            }

            .gs-filtr-container {

                label {

                    &:before {
                        border-color: lighten(@font-color, 30%) !important;
                    }
                }
            }

            .gs-filtr-container {
                border: 0;
                padding-left: 0;
                padding-right: 0;
                padding-top: 0 !important;
                padding-bottom: 8px !important;

                > strong {
                    display: block;
                    color: @header-color;
                    padding: 10px 0;
                    font-weight: 700;

                    span {
                        display: none;
                    }

                    &:first-letter {
                        text-transform: uppercase;
                    }
                }

                input {
                    display: none;
                }

                input:checked + label {

                    &:before {
                        border-color: @sec-color;
                        border-width: 2px;
                    }

                    &:after {
                        border-bottom: 2px solid @header-color;
                        border-left: 2px solid @header-color;
                        width: 10px;
                    }
                }

                label {
                    display: block;
                    padding: 7px 0 7px 29px;
                    line-height: 15px;
                    position: relative;
                    cursor: pointer;
                    user-select: none;
                    color: @font-color;

                    &:active {

                        &:before {
                            border-radius: 50%;
                        }
                    }

                    &:hover {

                        &:before {
                            border-width: 2px;
                        }
                    }

                    &:before {
                        content: '';
                        position: absolute;
                        top: 6px;
                        left: 0;
                        width: 18px;
                        height: 18px;
                        border: 1px solid @header-color;
                        transition: border-radius .1s ease-in-out;
                    }

                    &:after {
                        content: '';
                        position: absolute;
                        transform-origin: top left;
                        border: 0;
                        height: 7px;
                        width: 0;
                        left: 3px;
                        top: 15px;
                        transform: rotate(-45deg);
                        transition: width .2s ease;
                    }
                }

                &:not(.colors) {

                    ul {
                        max-height: 174px;
                        overflow: hidden;
                        overflow-y: auto;   
                        scrollbar-width: thin;

                        &::-webkit-scrollbar {
                            width: 10px;
                            height: 8px;
                            background-color: @border-color;
                        }

                        &::-webkit-scrollbar-thumb {
                            background-color: darken(@border-color, 20%);
                        }
                    }
                }

                &.colors {

                    ul {
                        display: flex;
                        flex-wrap: wrap;
                        gap: 4px;
                    }

                    li {
                        display: block;
                    }

                    label {
                        font-size: 0;
                        padding: 0;
                        width: 34px;
                        height: 34px;
                        border: 2px solid @border-color;
                        outline: 4px solid white;
                        outline-offset: -5px;

                        &:before,
                        &:after {
                            display: none;
                        }

                        &:hover {
                            border-color: @header-color;
                        }
                    }

                    input:checked + label {
                        border-color: @header-color;
                        outline-width: 3px;
                    }
                }
            }

            .price-filtering {

                #price-filter-amounts {
                    display: flex;

                    label,
                    i {
                        font-style: normal;
                        line-height: 36px;
                        margin-right: 8px;

                        &:last-of-type {
                            margin-left: 8px;
                        }
                    }

                    label:before {
                        display: none;
                    }

                    input {
                        display: block;
                        width: auto;
                        height: 36px;
                        width: 46px;
                        border: 1px solid @border-color;
                        background: transparent;
                        text-align: center;
                        padding: 0;
                        text-align: center;
                        flex-grow: 1;

                        &:nth-of-type(1) {
                            margin-right: 8px;
                        }
                    }
                }

                #price-filter-handles {
                    width: 85%;
                    margin: 10px auto 20px;
                }

                .noUi-target {
                    box-shadow: none;
                }

                .noUi-horizontal .noUi-handle {
                    box-shadow: none;
                    cursor: grab;

                    &.noUi-active {
                        cursor: grabbing;
                    }
                }
            }
        }

        #container.show-side-filter #filter {
            transform: translateX(0);
        }

        #container .inner-wrapper {

            &:before {
                content: '';
                position: fixed;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: rgba(0,0,0,.25);
                z-index: 10000;
                opacity: 0;
                visibility: hidden;
                transition: opacity .2s ease-in-out;
            }
        }

        #container.show-side-filter .inner-wrapper:before {
            visibility: visible;
            opacity: 1;
        }

        .price-filtering #price-filter-amounts i {
            color: @header-color;
        }
    }

    #filter .gs-filtr-container {

        &.no-filters-found {

            &:after {
                content: 'brak filtrów...';
                color: @error-color;
                display: block;
            }
        }

        > input {
            height: 30px;
            margin-bottom: 5px;
            padding: 0 10px;
            font-size: 13px;
        }

        label {

            &.hide {
                display: none !important;
            }
        }
    }
}

.price-filtering {

    .noUi-target {
        box-shadow: none;
    }

    .noUi-horizontal .noUi-handle {
        box-shadow: none;
        cursor: grab;
        width: 16px;
        height: 16px;
        background-color: @sec-color;
        border-radius: 3px;
        border: 0;

        &:before,
        &:after {
            display: none;
        }

        &.noUi-active {
            cursor: grabbing;
        }

        &.noUi-handle-upper {
            right: 0;
        }
    }
}

#price-filter-handles {
    margin: 18px auto 17px;
    height: 4px;
    background-color: @border-color;
    border: 0;
    border-radius: 0;
}

#price-filter-amounts {
    display: flex;
    justify-content: space-between;

    label,
    i {
        font-style: normal;
        line-height: 33px;
        color: @font-color;
    }

    label {
        padding: 0 !important;
    }

    label:before {
        display: none;
    }

    input {
        display: block;
        height: 33px;
        border: 1px solid @border-color;
        background: transparent;
        text-align: center;
        padding: 0;
        text-align: center;
        width: 68px;
    }
}

// LISTING WRAPPER
.listing-wrapper {
    .grid();
    grid-template-columns: minmax(0, 1fr);
    gap: 56px;
}

// CATEGORY DESCRIPTION
.category-headline {
    margin-bottom: 0;
}

.category-description {
    order: 1;
}

// NAVIGATION
.navigation-wrapper {
    display: none;
    flex-wrap: wrap;
    gap: 20px;
    margin-top: -12px;
    justify-content: space-between;

    &:has(#pills-container .single-pill),
    &:has(.sorter) {
        display: flex;
    }
}

// SORTING
.sorter {
    position: relative;
    display: flex;
    position: relative;
    z-index: 10;

    &.show-dropdown {

        .sorter-button:after {
            transform: scaleY(-1);
        }

        .sorter-listing {
            opacity: 1;
            visibility: visible;
            transform: translateY(0);
        }
    }

    &:before {
        content: 'Sortowanie';
        background-color: white;
        position: absolute;
        top: -20px;
        left: 0;
        font-size: 12px;
        font-weight: 700;
        color: @font-color;
        z-index: 11;
        pointer-events: none;
    }
}

.sorter-button {
    color: @font-color;
    min-width: 282px;
    height: 48px;
    text-align: left;
    position: relative;
    padding: 0 38px 0 16px;
    z-index: 1;
    background-color: @light-bg;
    border-radius: var(--borderRadius);

    &:after {
        content: '\e915';
        font-family: icomoon;
        position: absolute;
        top: 0;
        right: 0;
        width: 48px;
        height: 48px;
        color: @header-color;
        font-size: 14px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &:hover {
        background-color: darken(@light-bg, 5%);
    }
}

.sorter-listing {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    top: calc(100% + 5px);
    left: 0;
    background-color: white;
    .shadow();
    min-width: 100%;
    padding: 8px 0;
    transform: translateY(-5px);
    transition: all .2s ease-in-out;
    background-color: @light-bg;
    border-radius: var(--borderRadius);

    span {
        display: block;
    }

    input {
        display: none;

        &:checked + label {
            font-weight: 700;
            color: @header-color;
            text-decoration: none !important
        }
    }

    label {
        display: block;
        padding: 3px 16px;
        user-select: none;
        cursor: pointer;

        &:hover {
            text-decoration: underline;
        }
    }
}

// IE FALLBACK
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .sorter-button:before {
        display: none;
    }
}